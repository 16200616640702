exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-algos-js": () => import("./../../../src/pages/algos.js" /* webpackChunkName: "component---src-pages-algos-js" */),
  "component---src-pages-chart-types-js": () => import("./../../../src/pages/chartTypes.js" /* webpackChunkName: "component---src-pages-chart-types-js" */),
  "component---src-pages-charts-charts-json-type-js": () => import("./../../../src/pages/charts/{ChartsJson.type}.js" /* webpackChunkName: "component---src-pages-charts-charts-json-type-js" */),
  "component---src-pages-component-playground-index-js": () => import("./../../../src/pages/component-playground/index.js" /* webpackChunkName: "component---src-pages-component-playground-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-docker-index-js": () => import("./../../../src/pages/docker/index.js" /* webpackChunkName: "component---src-pages-docker-index-js" */),
  "component---src-pages-febs-js": () => import("./../../../src/pages/febs.js" /* webpackChunkName: "component---src-pages-febs-js" */),
  "component---src-pages-folio-index-js": () => import("./../../../src/pages/folio/index.js" /* webpackChunkName: "component---src-pages-folio-index-js" */),
  "component---src-pages-folio-slice-n-dice-times-index-js": () => import("./../../../src/pages/folio/slice-n-dice-times/index.js" /* webpackChunkName: "component---src-pages-folio-slice-n-dice-times-index-js" */),
  "component---src-pages-folio-world-map-index-js": () => import("./../../../src/pages/folio/world-map/index.js" /* webpackChunkName: "component---src-pages-folio-world-map-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intj-js": () => import("./../../../src/pages/intj.js" /* webpackChunkName: "component---src-pages-intj-js" */),
  "component---src-pages-js-js": () => import("./../../../src/pages/js.js" /* webpackChunkName: "component---src-pages-js-js" */),
  "component---src-pages-k-8-s-index-js": () => import("./../../../src/pages/k8s/index.js" /* webpackChunkName: "component---src-pages-k-8-s-index-js" */),
  "component---src-pages-linux-index-js": () => import("./../../../src/pages/linux/index.js" /* webpackChunkName: "component---src-pages-linux-index-js" */),
  "component---src-pages-misc-js": () => import("./../../../src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-pages-mongo-js": () => import("./../../../src/pages/mongo.js" /* webpackChunkName: "component---src-pages-mongo-js" */),
  "component---src-pages-nginx-js": () => import("./../../../src/pages/nginx.js" /* webpackChunkName: "component---src-pages-nginx-js" */),
  "component---src-pages-nlp-js": () => import("./../../../src/pages/nlp/[...].js" /* webpackChunkName: "component---src-pages-nlp-js" */),
  "component---src-pages-node-js": () => import("./../../../src/pages/node.js" /* webpackChunkName: "component---src-pages-node-js" */),
  "component---src-pages-recipes-index-js": () => import("./../../../src/pages/recipes/index.js" /* webpackChunkName: "component---src-pages-recipes-index-js" */),
  "component---src-pages-scratchpad-js": () => import("./../../../src/pages/scratchpad.js" /* webpackChunkName: "component---src-pages-scratchpad-js" */),
  "component---src-pages-scrum-index-js": () => import("./../../../src/pages/scrum/index.js" /* webpackChunkName: "component---src-pages-scrum-index-js" */),
  "component---src-pages-strengths-index-js": () => import("./../../../src/pages/strengths/index.js" /* webpackChunkName: "component---src-pages-strengths-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-the-social-world-index-js": () => import("./../../../src/pages/the-social-world/index.js" /* webpackChunkName: "component---src-pages-the-social-world-index-js" */),
  "component---src-templates-markdown-index-js": () => import("./../../../src/templates/markdown/index.js" /* webpackChunkName: "component---src-templates-markdown-index-js" */),
  "component---src-templates-nested-nav-index-js": () => import("./../../../src/templates/nestedNav/index.js" /* webpackChunkName: "component---src-templates-nested-nav-index-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

